const LOCATION_NAME = 'name';
const LOCATION_PHONE = 'phone';
const LOCATION_EMAIL = 'email';
const LOCATION_BUTTON = 'button';

export {
  LOCATION_NAME,
  LOCATION_PHONE,
  LOCATION_EMAIL,
  LOCATION_BUTTON,
};
