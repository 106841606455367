import update from 'immutability-helper';
import { PROGRESS, SUCCESS, FAILED } from "../config/types";
import {
  LANDINGS_GET_LIST_PROGRESS,
  LANDINGS_GET_LIST_SUCCESS,
  LANDINGS_GET_LIST_FAILED,
  LANDINGS_FORM_SUBMIT_PROGRESS,
  LANDINGS_FORM_SUBMIT_SUCCESS,
  LANDINGS_FORM_SUBMIT_FAILED,
  LANDINGS_GET_LANDING_PROGRESS,
  LANDINGS_GET_LANDING_SUCCESS,
  LANDINGS_GET_LANDING_FAILED,
  LANDINGS_GET_ASYNC_DATA_PROGRESS,
  LANDINGS_GET_ASYNC_DATA_SUCCESS,
  LANDINGS_GET_ASYNC_DATA_FAILED,
  LANDINGS_CLEAR_STATE,
  LANDINGS_DUPLICATE_SUBMIT_PROGRESS,
  LANDINGS_DUPLICATE_SUBMIT_SUCCESS,
  LANDINGS_DUPLICATE_SUBMIT_FAILED,
  LANDINGS_GET_FORM_DATA_PROGRESS,
  LANDINGS_GET_FORM_DATA_SUCCESS,
  LANDINGS_GET_FORM_DATA_FAILED,
  LANDINGS_GET_GLOBALS_PROGRESS,
  LANDINGS_GET_GLOBALS_SUCCESS,
  LANDINGS_GET_GLOBALS_FAILED,
} from '../actions/LandingsAction';

const initialState = {
  loadListStatus: '', // progress/success/failed
  loadActiveStatus: '', // progress/success/failed
  loadGlobalStatus: '', // progress/success/failed
  submitStatus: '', // progress/success/failed
  duplicateStatus: '', // progress/success/failed
  formDataStatus: '', // progress/success/failed
  asyncStatus: '', // progress/success/failed
  list: [],
  pagination: {},
  active: {},
  globals: {},
  formData: {},
  statistic: {},
  asyncData: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LANDINGS_GET_LIST_PROGRESS: {
      return update(
        state, {
          list: { $set: [] },
          pagination: { $set: {} },
          loadListStatus: { $set: PROGRESS },
        },
      );
    }
    case LANDINGS_GET_LIST_SUCCESS: {
      return update(
        state, {
          list: { $set: action.payload.list },
          pagination: { $set: action.payload.pagination },
          loadListStatus: { $set: SUCCESS },
        },
      );
    }
    case LANDINGS_GET_LIST_FAILED: {
      return update(
        state, {
          list: { $set: [] },
          pagination: { $set: {} },
          loadListStatus: { $set: FAILED },
        },
      );
    }
    case LANDINGS_FORM_SUBMIT_PROGRESS: {
      return update(
        state,
        { submitStatus: { $set: PROGRESS } },
      );
    }
    case LANDINGS_FORM_SUBMIT_SUCCESS: {
      return update(
        state,
        {
          submitStatus: { $set: SUCCESS },
        },
      );
    }
    case LANDINGS_FORM_SUBMIT_FAILED: {
      return update(
        state,
        { submitStatus: { $set: FAILED } },
      );
    }
    case LANDINGS_GET_LANDING_PROGRESS: {
      return update(
        state, {
          active: { $set: {} },
          statistic: { $set: {} },
          loadActiveStatus: { $set: PROGRESS },
        },
      );
    }
    case LANDINGS_GET_LANDING_SUCCESS: {
      return update(
        state, {
          active: { $set: action.payload.landing },
          statistic: { $set: action.payload.statistic },
          loadActiveStatus: { $set: SUCCESS },
        },
      );
    }
    case LANDINGS_GET_LANDING_FAILED: {
      return update(
        state, {
          active: { $set: {} },
          statistic: { $set: {} },
          loadActiveStatus: { $set: FAILED },
        },
      );
    }
    case LANDINGS_GET_ASYNC_DATA_PROGRESS: {
      return update(
        state, {
          asyncData: { $set: {} },
          asyncStatus: { $set: PROGRESS },
        },
      );
    }
    case LANDINGS_GET_ASYNC_DATA_SUCCESS: {
      return update(
        state, {
          asyncData: { $set: action.payload },
          asyncStatus: { $set: SUCCESS },
        },
      );
    }
    case LANDINGS_GET_ASYNC_DATA_FAILED: {
      return update(
        state, {
          asyncData: { $set: {} },
          asyncStatus: { $set: FAILED },
        },
      );
    }
    case LANDINGS_DUPLICATE_SUBMIT_PROGRESS: {
      return update(
        state,
        { duplicateStatus: { $set: PROGRESS } },
      );
    }
    case LANDINGS_DUPLICATE_SUBMIT_SUCCESS: {
      return update(
        state,
        {
          duplicateStatus: { $set: SUCCESS },
        },
      );
    }
    case LANDINGS_DUPLICATE_SUBMIT_FAILED: {
      return update(
        state,
        { duplicateStatus: { $set: FAILED } },
      );
    }
    case LANDINGS_GET_FORM_DATA_PROGRESS: {
      return update(
        state, {
          formData: { $set: {} },
          formDataStatus: { $set: PROGRESS },
        },
      );
    }
    case LANDINGS_GET_FORM_DATA_SUCCESS: {
      return update(
        state, {
          formData: { $set: action.payload.form },
          formDataStatus: { $set: SUCCESS },
        },
      );
    }
    case LANDINGS_GET_GLOBALS_FAILED: {
      return update(
        state, {
          formData: { $set: {} },
          formDataStatus: { $set: FAILED },
        },
      );
    }
    case LANDINGS_GET_GLOBALS_PROGRESS: {
      return update(
        state, {
          globals: { $set: {} },
          loadGlobalStatus: { $set: PROGRESS },
        },
      );
    }
    case LANDINGS_GET_GLOBALS_SUCCESS: {
      return update(
        state, {
          globals: { $set: action.payload },
          loadGlobalStatus: { $set: SUCCESS },
        },
      );
    }
    case LANDINGS_GET_FORM_DATA_FAILED: {
      return update(
        state, {
          globals: { $set: {} },
          loadGlobalStatus: { $set: FAILED },
        },
      );
    }
    case LANDINGS_CLEAR_STATE: {
      return update(
        state,
        {
          loadListStatus: { $set: '' },
          loadActiveStatus: { $set: '' },
          loadGlobalStatus: { $set: '' },
          submitStatus: { $set: '' },
          duplicateStatus: { $set: '' },
          formDataStatus: { $set: '' },
          asyncStatus: { $set: '' },
          list: { $set: [] },
          pagination: { $set: {} },
          active: { $set: {} },
          globals: { $set: {} },
          formData: { $set: [] },
          statistic: { $set: {} },
          asyncData: { $set: {} },
        },
      );
    }
    default:
      return state;
  }
}
