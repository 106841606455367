import update from 'immutability-helper';
import { PROGRESS, SUCCESS, FAILED } from "../config/types";
import {
  ANALYTICS_GET_ANALYTIC_PROGRESS,
  ANALYTICS_GET_ANALYTIC_SUCCESS,
  ANALYTICS_GET_ANALYTIC_FAILED,
  ANALYTICS_CLEAR_STATE,
} from "../actions/AnalyticsAction";

const initialState = {
  loadActiveStatus: '',
  active: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ANALYTICS_GET_ANALYTIC_PROGRESS: {
      return update(
        state, {
          active: { $set: {} },
          loadActiveStatus: { $set: PROGRESS },
        },
      );
    }
    case ANALYTICS_GET_ANALYTIC_SUCCESS: {
      return update(
        state, {
          active: { $set: action.payload.analytics },
          loadActiveStatus: { $set: SUCCESS },
        },
      );
    }
    case ANALYTICS_GET_ANALYTIC_FAILED: {
      return update(
        state, {
          active: { $set: {} },
          loadActiveStatus: { $set: FAILED },
        },
      );
    }
    case ANALYTICS_CLEAR_STATE: {
      return update(
        state,
        {
          loadActiveStatus: { $set: '' },
          active: { $set: {} },
        },
      );
    }
    default:
      return state;
  }
}
