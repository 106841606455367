import ws from "../ws";
import getToken from "../getToken";
import moment from "moment";
import "moment/locale/ru";
import { WEBINAR_PROCESSED, WEBINAR_FINISHED, WEBINAR_FINISHED_WITH_DEF_DATE } from "../config/webinars";

const WEBINARS_GET_LIST_PROGRESS = 'WEBINARS_GET_LIST_PROGRESS';
const WEBINARS_GET_LIST_SUCCESS = 'WEBINARS_GET_LIST_SUCCESS';
const WEBINARS_GET_LIST_FAILED = 'WEBINARS_GET_LIST_FAILED';
const WEBINARS_FORM_SUBMIT_PROGRESS = 'WEBINARS_FORM_SUBMIT_PROGRESS';
const WEBINARS_FORM_SUBMIT_SUCCESS = 'WEBINARS_FORM_SUBMIT_SUCCESS';
const WEBINARS_FORM_SUBMIT_FAILED = 'WEBINARS_FORM_SUBMIT_FAILED';
const WEBINARS_GET_WEBINAR_PROGRESS = 'WEBINARS_GET_WEBINAR_PROGRESS';
const WEBINARS_GET_WEBINAR_SUCCESS = 'WEBINARS_GET_WEBINAR_SUCCESS';
const WEBINARS_GET_WEBINAR_FAILED = 'WEBINARS_GET_WEBINAR_FAILED';
const WEBINARS_CLEAR_STATE = 'WEBINARS_CLEAR_STATE';

const webinarGetListProgress = () => ({
  type: WEBINARS_GET_LIST_PROGRESS,
});

const webinarGetListSuccess = payload => ({
  type: WEBINARS_GET_LIST_SUCCESS,
  payload,
});

const webinarGetListFailed = () => ({
  type: WEBINARS_GET_LIST_FAILED,
});

const webinarStartFormSubmit = () => ({
  type: WEBINARS_FORM_SUBMIT_PROGRESS,
});

const webinarFormSubmitSuccess = () => ({
  type: WEBINARS_FORM_SUBMIT_SUCCESS,
});

const webinarFormSubmitFailed = () => ({
  type: WEBINARS_FORM_SUBMIT_FAILED,
});

const webinarGetFormProgress = () => ({
  type: WEBINARS_GET_WEBINAR_PROGRESS,
});

const webinarGetFormSuccess = payload => ({
  type: WEBINARS_GET_WEBINAR_SUCCESS,
  payload,
});

const webinarGetFormFailed = () => ({
  type: WEBINARS_GET_WEBINAR_FAILED,
});

const webinarClearState = () => ({
  type: WEBINARS_CLEAR_STATE,
});

const convertListToTable = payload => {
  const { data } = payload;
  const {
    docs, hasNextPage, hasPrevPage, limit, nextPage, page, pagingCounter, prevPage, totalDocs, totalPages,
  } = data;

  const pagination = {
    docsNumber: docs.length,
    hasNextPage,
    hasPrevPage,
    limit,
    nextPage,
    page,
    pagingCounter,
    prevPage,
    totalDocs,
    totalPages,
  };

  let number = page * limit - limit;

  const list = docs.map(doc => ({
    number: ++number,
    id: doc.id,
    name: doc.name,
    type: doc.type,
    begin: moment(doc.begin).format("LLL"),
    duration: doc.duration,
    total: doc.status !== WEBINAR_PROCESSED ? doc.total : (doc.total !== null ? `${doc.total}/${doc.processed}` : null),
    status: doc.status === WEBINAR_FINISHED ? (doc.deferredDate !== null ? WEBINAR_FINISHED_WITH_DEF_DATE : doc.status) : doc.status,
    isSetDeferredDate: doc.deferredDate !== null,
    isArchive: doc.isArchive,
  }));

  return { list, pagination };
};

const getWebinarsList = params => dispatch => {
  const token = getToken();
  dispatch(webinarGetListProgress());

  ws.emit('api/app/webinars/list', { token, payload: params }, (data) => {
    const { status, payload } = data;

    if (status === 'ok') {
      dispatch(webinarGetListSuccess(convertListToTable(payload)));
    } else {
      dispatch(webinarGetListFailed());
    }
  });
};

const convertSetWebinarToDB = (groupsData) => {
  const keys = Object.keys(groupsData).map(key => parseInt(key, 10)).sort();
  const groups = [];
  const participantGroups = {};

  for (let i = 0; i < keys.length; i += 1) {
    const { metadata, list } = groupsData[keys[i]];
    const {
      name, lead, price, free, freeStatus, fieldsData,
    } = metadata;

    participantGroups[keys[i]] = list.map(participant => participant.id);

    if (keys[i] > 2) {
      groups.push({
        groupIndex: keys[i],
        name,
        lead,
        price: price || null,
        pipeline: free.value,
        statusId: freeStatus.value,
        customFields: fieldsData,
      });
    }
  }

  return { groups, participantGroups };
};

const convertDBWebinarToEdit = payload => {
  const {
    webinar: webinarFromDb, participants, pipelines: pipelinesFromDb, fields: fieldsFromDb, filters, filtersLabels,
  } = payload;
  const {
    _id: id, name, total, begin, duration, groups: groupsFromDb, period, status, deferredDate, isArchive,
  } = webinarFromDb;

  const pipelines = pipelinesFromDb.map(pipeline => {
    const { _id: id, name, statuses: statusesData, isArchive } = pipeline;
    const statuses = statusesData.sort((status1, status2) => status1.sort - status2.sort).map(status => ({
      value: status.amoId,
      label: status.name,
      color: status.color,
      disabled: status.isArchive,
    }));

    return { value: id, label: name, statuses, disabled: isArchive };
  });

  const fields = fieldsFromDb.map(field => {
    const { enums } = field;
    return { ...field, id: field._id, enums: enums.map(item => ({ value: item.amoId, label: item.value })) };
  });

  const webinar = {
    id,
    name,
    total,
    begin: moment(begin),
    end: moment(begin).add(duration, 'minutes'),
    duration,
    status,
    deferredDate: deferredDate !== null ? new Date(deferredDate) : null,
    isArchive,
    filters,
    period: period === null ? '' : String(period),
    filtersLabels,
  };

  const groups = {
    0: {
      metadata: {
        name: 'Общая группа',
      },
      list: [],
    },
    1: {
      metadata: {
        name: 'Корзина',
      },
      list: [],
    },
    2: {
      metadata: {
        name: 'Неудачные',
      },
      list: [],
    },
  };

  for (let i = 0; i < groupsFromDb.length; i += 1) {
    const {
      groupIndex, name, lead, price, pipeline, statusId, customFields,
    } = groupsFromDb[i];
    const free = pipelines.find(item => item.value === pipeline._id);
    const freeStatus = free ? free.statuses.find(status => status.value === statusId) : null;

    groups[groupIndex] = {
      metadata: {
        name,
        lead,
        price: price !== null ? String(price) : '',
        free: free || '',
        freeStatus: freeStatus || '',
        fieldsData: customFields,
      },
      list: [],
    };
  }

  for (let i = 0; i < participants.length; i += 1) {
    const view = participants[i].view ? moment(participants[i].view).startOf('minute') : null;
    const viewTill = participants[i].viewTill ? moment(participants[i].viewTill).endOf('minute') : null;
    const group = participants[i].group;
    const participant = {
      id: participants[i]._id,
      person: participants[i].person,
      finished: participants[i].finished,
      view: view !== null ? (view.isBefore(webinar.begin) ? webinar.begin.clone() : view) : null,
      viewTill: viewTill !== null ? (viewTill.isAfter(webinar.end) ? webinar.end.clone() : viewTill) : null,
      isBanned: participants[i].isBanned,
      clickFile: participants[i].clickFile,
      city: participants[i].city,
      country: participants[i].country,
      status: participants[i].status,
    };

    if (group in groups) {
      groups[group].list.push(participant);
    } else {
      groups[0].list.push(participant);
    }
  }

  return {
    webinar, groups, pipelines, fields,
  };
};

const getWebinar = webinarId => dispatch => {
  const token = getToken();
  dispatch(webinarGetFormProgress());

  ws.emit('api/app/webinars/getById', { token, payload: { webinarId } }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(webinarGetFormSuccess(convertDBWebinarToEdit(payload)));
    } else {
      dispatch(webinarGetFormFailed());
    }
  });
};

const updateWebinar = (webinarId, groups) => new Promise((resolve, reject) => {
  const token = getToken();
  const convertedData = convertSetWebinarToDB(groups);

  ws.emit('api/app/webinars/updateById', { token, payload: { webinarId, data: convertedData } }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      resolve();
    } else {
      reject();
    }
  });
});

const updateParticipant = (participantId, person) => new Promise((resolve, reject) => {
  const token = getToken();

  ws.emit('api/app/webinars/updateParticipant', { token, payload: { participantId, person } }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      resolve();
    } else {
      reject();
    }
  });
});

const setTransferDeferredDate = (webinarId, date) =>  new Promise((resolve, reject) => {
  const token = getToken();

  ws.emit('api/app/webinars/setTransferDeferredDate', { token, payload: { webinarId, date } }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      const { date } = payload;
      resolve(date);
    } else {
      reject();
    }
  });
});

const removeTransferDeferredDate = webinarId =>  new Promise((resolve, reject) => {
  const token = getToken();

  ws.emit('api/app/webinars/removeTransferDeferredDate', { token, payload: { webinarId } }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      resolve();
    } else {
      reject();
    }
  });
});

const setFilters = (webinarId, period, filters) =>  new Promise((resolve, reject) => {
  const token = getToken();

  ws.emit('api/app/webinars/setFilters', { token, payload: { webinarId, period: period || null, filters } }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      const { filters, period, filtersLabels } = payload;
      resolve({ filters, period, filtersLabels } );
    } else {
      reject();
    }
  });
});

const removeFilters = webinarId =>  new Promise((resolve, reject) => {
  const token = getToken();

  ws.emit('api/app/webinars/removeFilters', { token, payload: { webinarId } }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      resolve();
    } else {
      reject();
    }
  });
});

const startTransferData = webinarId => dispatch => {
  const token = getToken();
  dispatch(webinarStartFormSubmit());

  ws.emit('api/app/webinars/startTransferData', { token, payload: { webinarId } }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(webinarFormSubmitSuccess());
    } else {
      dispatch(webinarFormSubmitFailed());
    }
  });
};

export {
  WEBINARS_GET_LIST_PROGRESS,
  WEBINARS_GET_LIST_SUCCESS,
  WEBINARS_GET_LIST_FAILED,
  WEBINARS_FORM_SUBMIT_PROGRESS,
  WEBINARS_FORM_SUBMIT_SUCCESS,
  WEBINARS_FORM_SUBMIT_FAILED,
  WEBINARS_GET_WEBINAR_PROGRESS,
  WEBINARS_GET_WEBINAR_SUCCESS,
  WEBINARS_GET_WEBINAR_FAILED,
  WEBINARS_CLEAR_STATE,
  webinarClearState,
  getWebinarsList,
  getWebinar,
  updateWebinar,
  updateParticipant,
  setTransferDeferredDate,
  removeTransferDeferredDate,
  setFilters,
  removeFilters,
  startTransferData,
};
