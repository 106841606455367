import ws from '../ws';
import getToken from '../getToken';
import moment from 'moment';
import 'moment/locale/ru';

const RECURRENT_PAYMENTS_GET_LIST_PROGRESS = 'RECURRENT_PAYMENTS_GET_LIST_PROGRESS';
const RECURRENT_PAYMENTS_GET_LIST_SUCCESS = 'RECURRENT_PAYMENTS_GET_LIST_SUCCESS';
const RECURRENT_PAYMENTS_GET_LIST_FAILED = 'RECURRENT_PAYMENTS_GET_LIST_FAILED';
const RECURRENT_PAYMENTS_GET_RECURRENT_PAYMENT_PROGRESS = 'RECURRENT_PAYMENTS_GET_RECURRENT_PAYMENT_PROGRESS';
const RECURRENT_PAYMENTS_GET_RECURRENT_PAYMENT_SUCCESS = 'RECURRENT_PAYMENTS_GET_RECURRENT_PAYMENT_SUCCESS';
const RECURRENT_PAYMENTS_GET_RECURRENT_PAYMENT_FAILED = 'RECURRENT_PAYMENTS_GET_RECURRENT_PAYMENT_FAILED';
const RECURRENT_PAYMENTS_CLEAR_STATE = 'RECURRENT_PAYMENTS_CLEAR_STATE';

const recurrentPaymentGetListProgress = () => ({
  type: RECURRENT_PAYMENTS_GET_LIST_PROGRESS,
});

const recurrentPaymentGetListSuccess = payload => ({
  type: RECURRENT_PAYMENTS_GET_LIST_SUCCESS,
  payload,
});

const recurrentPaymentGetListFailed = () => ({
  type: RECURRENT_PAYMENTS_GET_LIST_FAILED,
});

const recurrentPaymentGetRecurrentPaymentProgress = () => ({
  type: RECURRENT_PAYMENTS_GET_RECURRENT_PAYMENT_PROGRESS,
});

const recurrentPaymentGetRecurrentPaymentSuccess = payload => ({
  type: RECURRENT_PAYMENTS_GET_RECURRENT_PAYMENT_SUCCESS,
  payload,
});

const recurrentPaymentGetRecurrentPaymentFailed = () => ({
  type: RECURRENT_PAYMENTS_GET_RECURRENT_PAYMENT_FAILED,
});

const recurrentPaymentClearState = () => ({
  type: RECURRENT_PAYMENTS_CLEAR_STATE,
});

const convertListToTable = payload => {
  const { data } = payload;
  const {
    docs, hasNextPage, hasPrevPage, limit, nextPage, page, pagingCounter, prevPage, totalDocs, totalPages,
  } = data;

  const pagination = {
    docsNumber: docs.length,
    hasNextPage,
    hasPrevPage,
    limit,
    nextPage,
    page,
    pagingCounter,
    prevPage,
    totalDocs,
    totalPages,
  };

  const getTelegramName = (telegram) => {
    const { name, surname, nickname } = telegram;
    let telegramName = '';

    if (name) {
      telegramName += name;
    }

    if (surname) {
      telegramName += ` ${surname}`;
    }

    if (nickname) {
      telegramName += ` (@${nickname})`;
    }

    return telegramName || null;
  };

  let number = page * limit - limit;

  const list = docs.map(doc => ({
    number: ++number,
    id: doc.id,
    email: doc.person.email,
    phone: doc.person.phone,
    name: doc.person.name,
    telegram: getTelegramName(doc.telegram),
    status: doc.status,
    createdAt: moment(doc.createdAt).format("LLL"),
  }));

  return { list, pagination };
};

const getRecurrentPaymentsList = params => dispatch => {
  const token = getToken();
  dispatch(recurrentPaymentGetListProgress());

  ws.emit('api/app/recurrentPayments/list', { token, payload: params }, (data) => {
    const { status, payload } = data;

    if (status === 'ok') {
      dispatch(recurrentPaymentGetListSuccess(convertListToTable(payload)));
    } else {
      dispatch(recurrentPaymentGetListFailed());
    }
  });
};

const convertDBRecurrentPaymentToEdit = payload => {
  const { payments, logs, maskedCard } = payload;
  return {
    ...payload,
    maskedCard: maskedCard || '',
    payments: payments.map((payment) => ({ ...payment, id: payment._id })),
    logs: logs.map((log) => ({ ...log, id: log._id })),
  };
};

const getRecurrentPayment = recurrentPaymentId => dispatch => {
  const token = getToken();
  dispatch(recurrentPaymentGetRecurrentPaymentProgress());

  ws.emit('api/app/recurrentPayments/getById', { token, payload: { recurrentPaymentId } }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(recurrentPaymentGetRecurrentPaymentSuccess(convertDBRecurrentPaymentToEdit(payload)));
    } else {
      dispatch(recurrentPaymentGetRecurrentPaymentFailed());
    }
  });
};

const changeContacts = (paymentId, { name, phone, email }) => new Promise((resolve, reject) => {
  const token = getToken();

  ws.emit('api/app/recurrentPayments/changeContacts', {
    token, payload: { paymentId, name, phone, email }
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      resolve('Данные пользователя были успешно обновлены!');
    } else {
      reject('Не удалось обновить данные');
    }
  });
});

const unsubscribe = (paymentId) => new Promise((resolve, reject) => {
  const token = getToken();

  ws.emit('api/app/recurrentPayments/unsubscribe', { token, payload: { paymentId } }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      const { nextPayment } = payload;
      const date = moment(nextPayment);
      let message = 'Подписка пользователя была отменена! Пользователь будет удален с Telegram канала SuperMama ';

      if (date.isAfter(moment())) {
        message = `${message} ${date.format('LL')}`;
      } else {
        message = `${message} сегодня в полночь.`;
      }

      resolve(message);
    } else {
      reject('Не удалось отменить подписку');
    }
  });
});

const unsubscribeImmediately = (paymentId) => new Promise((resolve, reject) => {
  const token = getToken();

  ws.emit('api/app/recurrentPayments/unsubscribeImmediately', {
    token, payload: { paymentId, sendMessage: false }
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      resolve('Подписка пользователя была отменена! Пользователь был удален с Telegram канала SuperMama!');
    } else {
      reject('Не удалось отменить подписку');
    }
  });
});

const removeFromTelegram = (paymentId) => new Promise((resolve, reject) => {
  const token = getToken();

  ws.emit('api/app/recurrentPayments/removeFromTelegram', { token, payload: { paymentId } }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      resolve('Пользователь был удален с Telegram канала SuperMama!');
    } else {
      reject('Не удалось удалить с Telegram канала');
    }
  });
});

const changeNextPaymentDate = (paymentId, date) => new Promise((resolve, reject) => {
  const token = getToken();

  ws.emit('api/app/recurrentPayments/changeNextPaymentDate', { token, payload: { paymentId, date } }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      const { date } = payload;
      resolve(date);
    } else {
      reject('Не удалось Обновить дату платежа');
    }
  });
});

export {
  RECURRENT_PAYMENTS_GET_LIST_PROGRESS,
  RECURRENT_PAYMENTS_GET_LIST_SUCCESS,
  RECURRENT_PAYMENTS_GET_LIST_FAILED,
  RECURRENT_PAYMENTS_GET_RECURRENT_PAYMENT_PROGRESS,
  RECURRENT_PAYMENTS_GET_RECURRENT_PAYMENT_SUCCESS,
  RECURRENT_PAYMENTS_GET_RECURRENT_PAYMENT_FAILED,
  RECURRENT_PAYMENTS_CLEAR_STATE,
  recurrentPaymentClearState,
  getRecurrentPaymentsList,
  getRecurrentPayment,
  changeContacts,
  unsubscribe,
  unsubscribeImmediately,
  removeFromTelegram,
  changeNextPaymentDate,
};
