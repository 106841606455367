import ws from "../ws";
import getToken from "../getToken";
import moment from "moment";
import "moment/locale/ru";

const PRODUCTS_GET_LIST_PROGRESS = 'PRODUCTS_GET_LIST_PROGRESS';
const PRODUCTS_GET_LIST_SUCCESS = 'PRODUCTS_GET_LIST_SUCCESS';
const PRODUCTS_GET_LIST_FAILED = 'PRODUCTS_GET_LIST_FAILED';
const PRODUCTS_FORM_SUBMIT_PROGRESS = 'PRODUCTS_FORM_SUBMIT_PROGRESS';
const PRODUCTS_FORM_SUBMIT_SUCCESS = 'PRODUCTS_FORM_SUBMIT_SUCCESS';
const PRODUCTS_FORM_SUBMIT_FAILED = 'PRODUCTS_FORM_SUBMIT_FAILED';
const PRODUCTS_GET_PRODUCT_PROGRESS = 'PRODUCTS_GET_PRODUCT_PROGRESS';
const PRODUCTS_GET_PRODUCT_SUCCESS = 'PRODUCTS_GET_PRODUCT_SUCCESS';
const PRODUCTS_GET_PRODUCT_FAILED = 'PRODUCTS_GET_PRODUCT_FAILED';
const PRODUCTS_GET_CODE_PROGRESS = 'PRODUCTS_GET_CODE_PROGRESS';
const PRODUCTS_GET_CODE_SUCCESS = 'PRODUCTS_GET_CODE_SUCCESS';
const PRODUCTS_GET_CODE_FAILED = 'PRODUCTS_GET_CODE_FAILED';
const PRODUCTS_GET_ASYNC_DATA_PROGRESS = 'PRODUCTS_GET_ASYNC_DATA_PROGRESS';
const PRODUCTS_GET_ASYNC_DATA_SUCCESS = 'PRODUCTS_GET_ASYNC_DATA_SUCCESS';
const PRODUCTS_GET_ASYNC_DATA_FAILED = 'PRODUCTS_GET_ASYNC_DATA_FAILED';
const PRODUCTS_DUPLICATE_SUBMIT_PROGRESS = 'PRODUCTS_DUPLICATE_SUBMIT_PROGRESS';
const PRODUCTS_DUPLICATE_SUBMIT_SUCCESS = 'PRODUCTS_DUPLICATE_SUBMIT_SUCCESS';
const PRODUCTS_DUPLICATE_SUBMIT_FAILED = 'PRODUCTS_DUPLICATE_SUBMIT_FAILED';
const PRODUCTS_CLEAR_STATE = 'PRODUCTS_CLEAR_STATE';

const productGetListProgress = () => ({
  type: PRODUCTS_GET_LIST_PROGRESS,
});

const productGetListSuccess = payload => ({
  type: PRODUCTS_GET_LIST_SUCCESS,
  payload,
});

const productGetListFailed = () => ({
  type: PRODUCTS_GET_LIST_FAILED,
});

const productStartFormSubmit = () => ({
  type: PRODUCTS_FORM_SUBMIT_PROGRESS,
});

const productFormSubmitSuccess = () => ({
  type: PRODUCTS_FORM_SUBMIT_SUCCESS,
});

const productFormSubmitFailed = () => ({
  type: PRODUCTS_FORM_SUBMIT_FAILED,
});

const productGetFormProgress = () => ({
  type: PRODUCTS_GET_PRODUCT_PROGRESS,
});

const productGetFormSuccess = payload => ({
  type: PRODUCTS_GET_PRODUCT_SUCCESS,
  payload,
});

const productGetFormFailed = () => ({
  type: PRODUCTS_GET_PRODUCT_FAILED,
});

const productGetCodeProgress = () => ({
  type: PRODUCTS_GET_CODE_PROGRESS,
});

const productGetCodeSuccess = payload => ({
  type: PRODUCTS_GET_CODE_SUCCESS,
  payload,
});

const productGetCodeFailed = () => ({
  type: PRODUCTS_GET_CODE_FAILED,
});

const productGetAsyncDataProgress = () => ({
  type: PRODUCTS_GET_ASYNC_DATA_PROGRESS,
});

const productGetAsyncDataSuccess = payload => ({
  type: PRODUCTS_GET_ASYNC_DATA_SUCCESS,
  payload,
});

const productGetAsyncDataFailed = () => ({
  type: PRODUCTS_GET_ASYNC_DATA_FAILED,
});

const productClearState = () => ({
  type: PRODUCTS_CLEAR_STATE,
});

const productStartDuplicateSubmit = () => ({
  type: PRODUCTS_DUPLICATE_SUBMIT_PROGRESS,
});

const productDuplicateSubmitSuccess = () => ({
  type: PRODUCTS_DUPLICATE_SUBMIT_SUCCESS,
});

const productDuplicateSubmitFailed = () => ({
  type: PRODUCTS_DUPLICATE_SUBMIT_FAILED,
});

const convertListToTable = payload => {
  const { data } = payload;
  const {
    docs, hasNextPage, hasPrevPage, limit, nextPage, page, pagingCounter, prevPage, totalDocs, totalPages,
  } = data;

  const pagination = {
    docsNumber: docs.length,
    hasNextPage,
    hasPrevPage,
    limit,
    nextPage,
    page,
    pagingCounter,
    prevPage,
    totalDocs,
    totalPages,
  };

  let number = page * limit - limit;

  const list = docs.map(doc => ({
    number: ++number,
    id: doc.id,
    name: doc.name,
    lead: doc.lead.name,
    needPaid: doc.lead.needPaid,
    isArchive: doc.isArchive,
    updatedAt: moment(doc.updatedAt).format("LLL"),
  }));

  return { list, pagination };
};

const getProductsList = params => dispatch => {
  const token = getToken();
  dispatch(productGetListProgress());

  ws.emit('api/app/products/list', { token, payload: params }, (data) => {
    const { status, payload } = data;

    if (status === 'ok') {
      dispatch(productGetListSuccess(convertListToTable(payload)));
    } else {
      dispatch(productGetListFailed());
    }
  });
};

const convertSetProductToDB = (data, fields) => {
  const {
    name, productName, lead, price, needPaid, free, freeStatus, paid: paidSelect, paidStatus, payment, amount, currency,
    firstMonthAmount, firstMonthCurrency, isRecurrentPayment, redirect, cancel,
  } = data;

  let paid;

  if (needPaid) {
    paid = {
      pipeline: paidSelect.value,
      statusId: paidStatus.value,
      payment: payment.value,
      amount: amount,
      currency: currency.value,
      isRecurrentPayment: isRecurrentPayment,
      firstMonthAmount: (isRecurrentPayment && firstMonthAmount) ? firstMonthAmount : null,
      firstMonthCurrency: (isRecurrentPayment && firstMonthAmount) ? firstMonthCurrency.value : null,
    };
  } else {
    paid = {
      pipeline: null,
      statusId: null,
      payment: null,
      amount: null,
      currency: null,
      isRecurrentPayment: false,
      firstMonthAmount: null,
      firstMonthCurrency: null,
    };
  }

  const fieldKeys = Object.keys(fields);

  return {
    name,
    productName,
    lead: {
      name: lead,
      price: price.length ? price : null,
      needPaid,
      free: {
        pipeline: free.value,
        statusId: freeStatus.value,
      },
      paid,
    },
    customFields: fieldKeys.reduce((result, key) => {
      if (fields[key].length) {
        result.push({ field: key, values: fields[key] });
      }

      return result;
    }, []),
    code: {
      redirect,
      cancel,
    },
  };
};

const createProduct = (data, fields) => dispatch => {
  const token = getToken();
  const convertedData = convertSetProductToDB(data, fields);

  dispatch(productStartFormSubmit());

  ws.emit('api/app/products/create', { token, payload: convertedData }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(productFormSubmitSuccess());
    } else {
      dispatch(productFormSubmitFailed());
    }
  });
};

const convertDBProductToEdit = payload => {
  const { product: productFromDB, landings: landingsFromDB } = payload;
  const {
    name: formName, productName, lead, customFields, created, modified, statistics, createdAt, updatedAt,
    isArchive, code,
  } = productFromDB;
  const { name: leadName, price, needPaid, free, paid } = lead;
  const { pipeline: freePipeline, statusId: freeStatusId } = free;
  const {
    pipeline: paidPipeline, statusId: paidStatusId, payment, amount, currency, isRecurrentPayment,
    firstMonthAmount, firstMonthCurrency,
  } = paid;
  const { redirect, cancel } = code;

  const getValueForSelect = data => ({ value: data._id, label: data.name });

  const getStatusValue = (pipeline, statusId) => {
    const { statuses } = pipeline;
    const { name: statusName } = statuses.find(status => status.amoId === statusId);

    return { value: statusId, label: statusName };
  };

  const product = {
    name: formName,
    productName,
    lead: leadName,
    needPaid,
    free: getValueForSelect(freePipeline),
    freeStatus: getStatusValue(freePipeline, freeStatusId),
    redirect,
    cancel,
  };

  if (price) {
    product.price = price.toString();
  }

  if (paidPipeline) {
    product.paid = getValueForSelect(paidPipeline);
    product.paidStatus = getStatusValue(paidPipeline, paidStatusId);
    product.payment = { value: payment._id, label: payment.name, type: payment.type };
    product.amount = amount.toString();
    product.currency = getValueForSelect(currency);
    product.isRecurrentPayment = typeof isRecurrentPayment === 'boolean' ? isRecurrentPayment : false;
    product.firstMonthAmount = typeof firstMonthAmount !== 'undefined' && firstMonthAmount !== null
      ? firstMonthAmount.toString()
      : "";
    product.firstMonthCurrency = typeof firstMonthCurrency !== 'undefined' && firstMonthCurrency !== null
      ? getValueForSelect(firstMonthCurrency)
      : "";
  }

  const statistic = {
    created: `${created.name} ${created.surname}`,
    createdAt: moment(createdAt).format("LLL"),
    updated: `${modified.name} ${modified.surname}`,
    updatedAt: moment(updatedAt).format("LLL"),
    isArchive,
    leads: statistics.leads,
  };

  const landings = landingsFromDB.map((landing) => ({
    id: landing._id,
    name: landing.name,
    url: landing.url,
  }));

  return { product, landings, fields: customFields, statistic };
};

const getProduct = productId => dispatch => {
  const token = getToken();
  dispatch(productGetFormProgress());

  ws.emit('api/app/products/getById', { token, payload: { productId } }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(productGetFormSuccess(convertDBProductToEdit(payload)));
    } else {
      dispatch(productGetFormFailed());
    }
  });
};

const updateProduct = (id, data, fields) => dispatch => {
  const token = getToken();
  const convertedData = convertSetProductToDB(data, fields);

  dispatch(productStartFormSubmit());

  ws.emit('api/app/products/updateById', { token, payload: { productId: id, data: convertedData} }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(productFormSubmitSuccess());
    } else {
      dispatch(productFormSubmitFailed());
    }
  });
};

const transformAsyncData = data => ({
  pipelines: data.pipelines.map(pipeline => {
    const { id, name, statuses: statusesData, isArchive } = pipeline;
    const statuses = statusesData.sort((status1, status2) => status1.sort - status2.sort).map(status => ({
      value: status.amoId,
      label: status.name,
      color: status.color,
      disabled: status.isArchive,
    }));

    return { value: id, label: name, statuses, disabled: isArchive };
  }),
  payments: data.payments.map(payment => ({ value: payment.id, label: payment.name, type: payment.type })),
  currencies: data.currencies.map(currency => ({ value: currency.id, label: currency.name })),
  fields: data.fields.map(field => {
    const { enums } = field;
    return { ...field, enums: enums.map(item => ({ value: item.amoId, label: item.value })) };
  }),
  uah: data.currencies.filter(currency => currency.currency === 'uah')
    .map(currency => ({ value: currency.id, label: currency.name }))[0],
});

const getAsyncData = () => dispatch => {
  const token = getToken();
  dispatch(productGetAsyncDataProgress());

  ws.emit('api/app/products/getAsyncData', { token }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(productGetAsyncDataSuccess(transformAsyncData(payload)));
    } else {
      dispatch(productGetAsyncDataFailed());
    }
  });
};

const getProductSettings = productId => dispatch => {
  const token = getToken();
  dispatch(productGetCodeProgress());

  ws.emit('api/app/products/getProductCodeSetting', { token, payload: { productId } }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(productGetCodeSuccess(payload));
    } else {
      dispatch(productGetCodeFailed());
    }
  });
};

const setProductSettings = (productId, data) => dispatch => {
  const token = getToken();
  dispatch(productStartFormSubmit());

  ws.emit('api/app/products/setProductCodeSetting', { token, payload: { productId, data } }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(productFormSubmitSuccess());
    } else {
      dispatch(productFormSubmitFailed());
    }
  });
};

const createDuplicate = productId => dispatch => {
  const token = getToken();
  dispatch(productStartDuplicateSubmit());

  ws.emit('api/app/products/createDuplicate', { token, payload: { productId } }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(productDuplicateSubmitSuccess());
    } else {
      dispatch(productDuplicateSubmitFailed());
    }
  });
};

export {
  PRODUCTS_GET_LIST_PROGRESS,
  PRODUCTS_GET_LIST_SUCCESS,
  PRODUCTS_GET_LIST_FAILED,
  PRODUCTS_FORM_SUBMIT_PROGRESS,
  PRODUCTS_FORM_SUBMIT_SUCCESS,
  PRODUCTS_FORM_SUBMIT_FAILED,
  PRODUCTS_GET_PRODUCT_PROGRESS,
  PRODUCTS_GET_PRODUCT_SUCCESS,
  PRODUCTS_GET_PRODUCT_FAILED,
  PRODUCTS_GET_CODE_PROGRESS,
  PRODUCTS_GET_CODE_SUCCESS,
  PRODUCTS_GET_CODE_FAILED,
  PRODUCTS_GET_ASYNC_DATA_PROGRESS,
  PRODUCTS_GET_ASYNC_DATA_SUCCESS,
  PRODUCTS_GET_ASYNC_DATA_FAILED,
  PRODUCTS_CLEAR_STATE,
  PRODUCTS_DUPLICATE_SUBMIT_PROGRESS,
  PRODUCTS_DUPLICATE_SUBMIT_SUCCESS,
  PRODUCTS_DUPLICATE_SUBMIT_FAILED,
  productClearState,
  getProductsList,
  createProduct,
  getProduct,
  updateProduct,
  getAsyncData,
  getProductSettings,
  setProductSettings,
  createDuplicate,
};
