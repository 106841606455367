import ws from '../ws';
import getToken from '../getToken';

const SETTINGS_FORM_SUBMIT_AMO_PROGRESS = 'SETTINGS_FORM_SUBMIT_AMO_PROGRESS';
const SETTINGS_FORM_SUBMIT_AMO_SUCCESS = 'SETTINGS_FORM_SUBMIT_AMO_SUCCESS';
const SETTINGS_FORM_SUBMIT_AMO_FAILED = 'SETTINGS_FORM_SUBMIT_AMO_FAILED';
const SETTINGS_FORM_SUBMIT_STRIPE_PROGRESS = 'SETTINGS_FORM_SUBMIT_STRIPE_PROGRESS';
const SETTINGS_FORM_SUBMIT_STRIPE_SUCCESS = 'SETTINGS_FORM_SUBMIT_STRIPE_SUCCESS';
const SETTINGS_FORM_SUBMIT_STRIPE_FAILED = 'SETTINGS_FORM_SUBMIT_STRIPE_FAILED';
const SETTINGS_FORM_SUBMIT_FONDY_PROGRESS = 'SETTINGS_FORM_SUBMIT_FONDY_PROGRESS';
const SETTINGS_FORM_SUBMIT_FONDY_SUCCESS = 'SETTINGS_FORM_SUBMIT_FONDY_SUCCESS';
const SETTINGS_FORM_SUBMIT_FONDY_FAILED = 'SETTINGS_FORM_SUBMIT_FONDY_FAILED';
const SETTINGS_FORM_SUBMIT_FONDY_RECURRING_PROGRESS = 'SETTINGS_FORM_SUBMIT_FONDY_RECURRING_PROGRESS';
const SETTINGS_FORM_SUBMIT_FONDY_RECURRING_SUCCESS = 'SETTINGS_FORM_SUBMIT_FONDY_RECURRING_SUCCESS';
const SETTINGS_FORM_SUBMIT_FONDY_RECURRING_FAILED = 'SETTINGS_FORM_SUBMIT_FONDY_RECURRING_FAILED';
const SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_PROGRESS = 'SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_PROGRESS';
const SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_SUCCESS = 'SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_SUCCESS';
const SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_FAILED = 'SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_FAILED';
const SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_RECURRING_PROGRESS = 'SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_RECURRING_PROGRESS';
const SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_RECURRING_SUCCESS = 'SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_RECURRING_SUCCESS';
const SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_RECURRING_FAILED = 'SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_RECURRING_FAILED';
const SETTINGS_GET_SETTING_PROGRESS = 'SETTINGS_GET_SETTING_PROGRESS';
const SETTINGS_GET_SETTING_SUCCESS = 'SETTINGS_GET_SETTING_SUCCESS';
const SETTINGS_GET_SETTING_FAILED = 'SETTINGS_GET_SETTING_FAILED';
const SETTINGS_CLEAR_STATE = 'SETTINGS_CLEAR_STATE';

const settingStartFormAmoSubmit = () => ({
  type: SETTINGS_FORM_SUBMIT_AMO_PROGRESS,
});

const settingFormSubmitAmoSuccess = (payload) => ({
  type: SETTINGS_FORM_SUBMIT_AMO_SUCCESS,
  payload,
});

const settingFormSubmitAmoFailed = () => ({
  type: SETTINGS_FORM_SUBMIT_AMO_FAILED,
});

const settingStartFormStripeSubmit = () => ({
  type: SETTINGS_FORM_SUBMIT_STRIPE_PROGRESS,
});

const settingFormSubmitStripeSuccess = (payload) => ({
  type: SETTINGS_FORM_SUBMIT_STRIPE_SUCCESS,
  payload,
});

const settingFormSubmitStripeFailed = () => ({
  type: SETTINGS_FORM_SUBMIT_STRIPE_FAILED,
});

const settingStartFormFondySubmit = () => ({
  type: SETTINGS_FORM_SUBMIT_FONDY_PROGRESS,
});

const settingFormSubmitFondySuccess = (payload) => ({
  type: SETTINGS_FORM_SUBMIT_FONDY_SUCCESS,
  payload,
});

const settingFormSubmitFondyFailed = () => ({
  type: SETTINGS_FORM_SUBMIT_FONDY_FAILED,
});

const settingStartFormFondyRecurringSubmit = () => ({
  type: SETTINGS_FORM_SUBMIT_FONDY_RECURRING_PROGRESS,
});

const settingFormSubmitFondyRecurringSuccess = (payload) => ({
  type: SETTINGS_FORM_SUBMIT_FONDY_RECURRING_SUCCESS,
  payload,
});

const settingFormSubmitFondyRecurringFailed = () => ({
  type: SETTINGS_FORM_SUBMIT_FONDY_RECURRING_FAILED,
});

const settingStartFormWayForPaySubmit = () => ({
  type: SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_PROGRESS,
});

const settingFormSubmitWayForPaySuccess = (payload) => ({
  type: SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_SUCCESS,
  payload,
});

const settingFormSubmitWayForPayFailed = () => ({
  type: SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_FAILED,
});

const settingStartFormWayForPayRecurringSubmit = () => ({
  type: SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_RECURRING_PROGRESS,
});

const settingFormSubmitWayForPayRecurringSuccess = (payload) => ({
  type: SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_RECURRING_SUCCESS,
  payload,
});

const settingFormSubmitWayForPayRecurringFailed = () => ({
  type: SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_RECURRING_FAILED,
});

const settingGetSettingProgress = () => ({
  type: SETTINGS_GET_SETTING_PROGRESS,
});

const settingGetSettingSuccess = payload => ({
  type: SETTINGS_GET_SETTING_SUCCESS,
  payload,
});

const settingGetSettingFailed = () => ({
  type: SETTINGS_GET_SETTING_FAILED,
});

const settingClearState = () => ({
  type: SETTINGS_CLEAR_STATE,
});

const convertDBSettingToEdit = payload => {
  const { settings: settingsFromDB, asyncData } = payload;
  const { merchants, wayForPayMerchants, keys } = asyncData;
  const {
    amo, stripe, fondy, fondyRecurring, wayForPay, wayForPayRecurring, dates,
  } = settingsFromDB;

  const { merchant } = fondy;
  const { merchant: recurringMerchant } = fondyRecurring;
  const { merchant: wayForPayMerchant } = wayForPay;
  const { merchant: wayForPayRecurringMerchant } = wayForPayRecurring;
  const getMerchantLabel = (merchant) => `${merchant.name} (ID: ${merchant.merchantId})`;

  const settings = {
    amo,
    stripe,
    fondy: {
      merchant: merchant ? ({ value: merchant.id, label: getMerchantLabel(merchant) }) : null,
    },
    fondyRecurring: {
      merchant: recurringMerchant ? ({ value: recurringMerchant.id, label: getMerchantLabel(recurringMerchant) }) : null,
    },
    wayForPay: {
      merchant: wayForPayMerchant ? ({ value: wayForPayMerchant.id, label: getMerchantLabel(wayForPayMerchant) }) : null,
    },
    wayForPayRecurring: {
      merchant: wayForPayRecurringMerchant ? ({ value: wayForPayRecurringMerchant.id, label: getMerchantLabel(wayForPayRecurringMerchant) }) : null,
    },
    dates,
  };

  return {
    settings,
    asyncData: {
      merchants: merchants.map(merchant => ({ value: merchant.id, label: getMerchantLabel(merchant) })),
      wayForPayMerchants: wayForPayMerchants.map(merchant => ({ value: merchant.id, label: getMerchantLabel(merchant) })),
      keys,
    },
  };
};

const getSettings = () => dispatch => {
  const token = getToken();
  dispatch(settingGetSettingProgress());

  ws.emit('api/app/settings/get', { token }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(settingGetSettingSuccess(convertDBSettingToEdit(payload)));
    } else {
      dispatch(settingGetSettingFailed());
    }
  });
};

const updateAmoSettings = data => dispatch => {
  const token = getToken();
  dispatch(settingStartFormAmoSubmit());

  ws.emit('api/app/settings/updateAmo', { token, payload: { data } }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(settingFormSubmitAmoSuccess(payload));
    } else {
      dispatch(settingFormSubmitAmoFailed());
    }
  });
};

const updateStripeSettings = data => dispatch => {
  const token = getToken();
  dispatch(settingStartFormStripeSubmit());

  ws.emit('api/app/settings/updateStripe', { token, payload: { data } }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(settingFormSubmitStripeSuccess(payload));
    } else {
      dispatch(settingFormSubmitStripeFailed());
    }
  });
};

const updateFondySettings = data => dispatch => {
  const token = getToken();
  dispatch(settingStartFormFondySubmit());

  ws.emit('api/app/settings/updateFondy', { token, payload: { data } }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(settingFormSubmitFondySuccess(payload));
    } else {
      dispatch(settingFormSubmitFondyFailed());
    }
  });
};

const updateFondyRecurringSettings = data => dispatch => {
  const token = getToken();
  dispatch(settingStartFormFondyRecurringSubmit());

  ws.emit('api/app/settings/updateFondyRecurring', { token, payload: { data } }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(settingFormSubmitFondyRecurringSuccess(payload));
    } else {
      dispatch(settingFormSubmitFondyRecurringFailed());
    }
  });
};

const updateWayForPaySettings = data => dispatch => {
  const token = getToken();
  dispatch(settingStartFormWayForPaySubmit());

  ws.emit('api/app/settings/updateWayForPay', { token, payload: { data } }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(settingFormSubmitWayForPaySuccess(payload));
    } else {
      dispatch(settingFormSubmitWayForPayFailed());
    }
  });
};

const updateWayForPayRecurringSettings = data => dispatch => {
  const token = getToken();
  dispatch(settingStartFormWayForPayRecurringSubmit());

  ws.emit('api/app/settings/updateWayForPayRecurring', { token, payload: { data } }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(settingFormSubmitWayForPayRecurringSuccess(payload));
    } else {
      dispatch(settingFormSubmitWayForPayRecurringFailed());
    }
  });
};

export {
  SETTINGS_FORM_SUBMIT_AMO_PROGRESS,
  SETTINGS_FORM_SUBMIT_AMO_SUCCESS,
  SETTINGS_FORM_SUBMIT_AMO_FAILED,
  SETTINGS_FORM_SUBMIT_STRIPE_PROGRESS,
  SETTINGS_FORM_SUBMIT_STRIPE_SUCCESS,
  SETTINGS_FORM_SUBMIT_STRIPE_FAILED,
  SETTINGS_FORM_SUBMIT_FONDY_PROGRESS,
  SETTINGS_FORM_SUBMIT_FONDY_SUCCESS,
  SETTINGS_FORM_SUBMIT_FONDY_FAILED,
  SETTINGS_FORM_SUBMIT_FONDY_RECURRING_PROGRESS,
  SETTINGS_FORM_SUBMIT_FONDY_RECURRING_SUCCESS,
  SETTINGS_FORM_SUBMIT_FONDY_RECURRING_FAILED,
  SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_PROGRESS,
  SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_SUCCESS,
  SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_FAILED,
  SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_RECURRING_PROGRESS,
  SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_RECURRING_SUCCESS,
  SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_RECURRING_FAILED,
  SETTINGS_GET_SETTING_PROGRESS,
  SETTINGS_GET_SETTING_SUCCESS,
  SETTINGS_GET_SETTING_FAILED,
  SETTINGS_CLEAR_STATE,
  settingClearState,
  getSettings,
  updateAmoSettings,
  updateStripeSettings,
  updateFondySettings,
  updateFondyRecurringSettings,
  updateWayForPaySettings,
  updateWayForPayRecurringSettings,
};
