import update from 'immutability-helper';
import { PROGRESS, SUCCESS, FAILED } from "../config/types";
import {
  SETTINGS_FORM_SUBMIT_AMO_PROGRESS,
  SETTINGS_FORM_SUBMIT_AMO_SUCCESS,
  SETTINGS_FORM_SUBMIT_AMO_FAILED,
  SETTINGS_FORM_SUBMIT_STRIPE_PROGRESS,
  SETTINGS_FORM_SUBMIT_STRIPE_SUCCESS,
  SETTINGS_FORM_SUBMIT_STRIPE_FAILED,
  SETTINGS_FORM_SUBMIT_FONDY_PROGRESS,
  SETTINGS_FORM_SUBMIT_FONDY_SUCCESS,
  SETTINGS_FORM_SUBMIT_FONDY_FAILED,
  SETTINGS_FORM_SUBMIT_FONDY_RECURRING_PROGRESS,
  SETTINGS_FORM_SUBMIT_FONDY_RECURRING_SUCCESS,
  SETTINGS_FORM_SUBMIT_FONDY_RECURRING_FAILED,
  SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_PROGRESS,
  SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_SUCCESS,
  SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_FAILED,
  SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_RECURRING_PROGRESS,
  SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_RECURRING_SUCCESS,
  SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_RECURRING_FAILED,
  SETTINGS_GET_SETTING_PROGRESS,
  SETTINGS_GET_SETTING_SUCCESS,
  SETTINGS_GET_SETTING_FAILED,
  SETTINGS_CLEAR_STATE,
} from '../actions/SettingsAction';

const initialState = {
  loadActiveStatus: '',
  submitAmoStatus: '',
  amoDate: {},
  submitStripeStatus: '',
  stripeDate: {},
  submitFondyStatus: '',
  fondyDate: {},
  submitFondyRecurringStatus: '',
  fondyRecurringDate: {},
  submitWayForPayStatus: '',
  wayForPayDate: {},
  submitWayForPayRecurringStatus: '',
  wayForPayRecurringDate: {},
  active: {},
  asyncData: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SETTINGS_FORM_SUBMIT_AMO_PROGRESS: {
      return update(
        state, {
          submitAmoStatus: { $set: PROGRESS },
          amoDate: { $set: {} },
        },
      );
    }
    case SETTINGS_FORM_SUBMIT_AMO_SUCCESS: {
      return update(
        state, {
          submitAmoStatus: { $set: SUCCESS },
          amoDate: { $set: action.payload.date },
        },
      );
    }
    case SETTINGS_FORM_SUBMIT_AMO_FAILED: {
      return update(
        state, {
          submitAmoStatus: { $set: FAILED },
          amoDate: { $set: {} },
        },
      );
    }
    case SETTINGS_FORM_SUBMIT_STRIPE_PROGRESS: {
      return update(
        state, {
          submitStripeStatus: { $set: PROGRESS },
          stripeDate: { $set: {} },
        },
      );
    }
    case SETTINGS_FORM_SUBMIT_STRIPE_SUCCESS: {
      return update(
        state, {
          submitStripeStatus: { $set: SUCCESS },
          stripeDate: { $set: action.payload.date },
        },
      );
    }
    case SETTINGS_FORM_SUBMIT_STRIPE_FAILED: {
      return update(
        state, {
          submitStripeStatus: { $set: FAILED },
          stripeDate: { $set: {} },
        },
      );
    }
    case SETTINGS_FORM_SUBMIT_FONDY_PROGRESS: {
      return update(
        state, {
          submitFondyStatus: { $set: PROGRESS },
          fondyDate: { $set: {} },
        },
      );
    }
    case SETTINGS_FORM_SUBMIT_FONDY_SUCCESS: {
      return update(
        state, {
          submitFondyStatus: { $set: SUCCESS },
          fondyDate: { $set: action.payload.date },
        },
      );
    }
    case SETTINGS_FORM_SUBMIT_FONDY_FAILED: {
      return update(
        state, {
          submitFondyStatus: { $set: FAILED },
          fondyDate: { $set: {} },
        },
      );
    }
    case SETTINGS_FORM_SUBMIT_FONDY_RECURRING_PROGRESS: {
      return update(
        state, {
          submitFondyRecurringStatus: { $set: PROGRESS },
          fondyRecurringDate: { $set: {} },
        },
      );
    }
    case SETTINGS_FORM_SUBMIT_FONDY_RECURRING_SUCCESS: {
      return update(
        state, {
          submitFondyRecurringStatus: { $set: SUCCESS },
          fondyRecurringDate: { $set: action.payload.date },
        },
      );
    }
    case SETTINGS_FORM_SUBMIT_FONDY_RECURRING_FAILED: {
      return update(
        state, {
          submitFondyRecurringStatus: { $set: FAILED },
          fondyRecurringDate: { $set: {} },
        },
      );
    }
    case SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_PROGRESS: {
      return update(
        state, {
          submitWayForPayStatus: { $set: PROGRESS },
          wayForPayDate: { $set: {} },
        },
      );
    }
    case SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_SUCCESS: {
      return update(
        state, {
          submitWayForPayStatus: { $set: SUCCESS },
          wayForPayDate: { $set: action.payload.date },
        },
      );
    }
    case SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_FAILED: {
      return update(
        state, {
          submitWayForPayStatus: { $set: FAILED },
          wayForPayDate: { $set: {} },
        },
      );
    }
    case SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_RECURRING_PROGRESS: {
      return update(
        state, {
          submitWayForPayRecurringStatus: { $set: PROGRESS },
          wayForPayRecurringDate: { $set: {} },
        },
      );
    }
    case SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_RECURRING_SUCCESS: {
      return update(
        state, {
          submitWayForPayRecurringStatus: { $set: SUCCESS },
          wayForPayRecurringDate: { $set: action.payload.date },
        },
      );
    }
    case SETTINGS_FORM_SUBMIT_WAY_FOR_PAY_RECURRING_FAILED: {
      return update(
        state, {
          submitWayForPayRecurringStatus: { $set: FAILED },
          wayForPayRecurringDate: { $set: {} },
        },
      );
    }
    case SETTINGS_GET_SETTING_PROGRESS: {
      return update(
        state, {
          active: { $set: {} },
          asyncData: { $set: {} },
          loadActiveStatus: { $set: PROGRESS },
        },
      );
    }
    case SETTINGS_GET_SETTING_SUCCESS: {
      return update(
        state, {
          active: { $set: action.payload.settings },
          asyncData: { $set: action.payload.asyncData },
          loadActiveStatus: { $set: SUCCESS },
        },
      );
    }
    case SETTINGS_GET_SETTING_FAILED: {
      return update(
        state, {
          active: { $set: {} },
          asyncData: { $set: {} },
          loadActiveStatus: { $set: FAILED },
        },
      );
    }
    case SETTINGS_CLEAR_STATE: {
      return update(
        state,
        {
          loadActiveStatus: { $set: '' },
          submitAmoStatus: { $set: '' },
          amoDate: { $set: {} },
          submitStripeStatus: { $set: '' },
          stripeDate: { $set: {} },
          submitFondyStatus: { $set: '' },
          fondyDate: { $set: {} },
          submitFondyRecurringStatus: { $set: '' },
          fondyRecurringDate: { $set: {} },
          submitWayForPayStatus: { $set: '' },
          wayForPayDate: { $set: {} },
          submitWayForPayRecurringStatus: { $set: '' },
          wayForPayRecurringDate: { $set: {} },
          active: { $set: {} },
          asyncData: { $set: {} },
        },
      );
    }
    default:
      return state;
  }
}
